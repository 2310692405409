@charset "utf-8";
@import url("../css/variable.css");
/*
1. Common 
2. Header 
3. Home Banner Section
4. Partners Section 
5. About Us
6. info Section
7. Our Industries
8. Latest News
9. Newsletter 
10.Footer
11.Responsive
*/

/* Common Css */

body {
  font-family: "Gilroy";
  margin: 0;
  padding: 0;
  line-height: inherit;
  overflow-x: hidden;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  overflow-x: hidden;
}

* {
  border: medium none;
  margin: 0;
  padding: 0;
}
h1 {
  font-size: 66px;
  font-weight: 600;
  line-height: 82.5px;
  letter-spacing: -0.5px;
}
h2 {
  font-weight: 600;
  font-size: 50px;
  line-height: 62.5px;
  color: var(--black);
}

h3 {
  font-size: 37px;
  line-height: 42.2px;
  letter-spacing: 0em;
  font-weight: 600;
  color: var(--black);
  margin-bottom: 20px;
}
h3.orange-heading-text {
  font-weight: 600;
  font-size: 37px;
  line-height: 42.2px;
  color: var(--orange);
}
p {
  font-size: 16px;
  line-height: 20px;
  font-weight: 400;
  letter-spacing: 0em;
  color: var(--para-text);
  margin: 0;
}
.bld {
  font-weight: 900;
}
ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

a {
  text-decoration: none;
  outline: none !important;
  margin: 0;
  transition: 0.5s;
}
a h1 {
  margin: 0;
  padding: 0;
  line-height: initial;
  font-size: inherit;
}
a:hover {
  text-decoration: none;
  outline: none;
}

textarea {
  resize: none;
}

input,
button {
  outline: none !important;
  box-shadow: none !important;
}
.primary-link {
  background-image: none;
  border-style: solid;
  border-width: 1px;
  color: inherit;
  cursor: pointer;
  display: block;
  font-size: 18px;
  overflow: hidden;
  position: relative;
  text-decoration: none;
  z-index: 0;
  text-align: left;
}

.primary-link:before {
  bottom: 0;
  content: "";
  display: block;
  left: auto;
  position: absolute;
  right: 0;
  top: 0;
  -webkit-transition: left 0.25s ease-in-out, right 0.25s ease-in-out,
    width 0.25s ease-in-out;
  transition: left 0.25s ease-in-out, right 0.25s ease-in-out,
    width 0.25s ease-in-out;
  width: 0;
  z-index: -1;
}

.primary-link:hover:before {
  left: 0;
  right: auto;
  width: 100%;
}

.primary-link--dark,
.dropdown .primary-link--dark {
  border-color: #ff3700;
  color: #2a2a2a;
}
.show > .btn-primary.dropdown-toggle.primary-link {
  color: #2a2a2a;
}
.show > .btn-primary.dropdown-toggle.primary-link:hover {
  color: var(--white);
}
.dropdown .primary-link--dark {
  background-color: transparent;
}
.primary-link--dark:before {
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#840d81),
    to(#fe6b45)
  );
  background: linear-gradient(90deg, #840d81, #fe6b45);
}

.primary-link--dark:active {
  border-color: var(--orange);
}
.btn-check:active + .btn-primary,
.btn-check:checked + .btn-primary,
.btn-primary.active,
.btn-primary:active,
.show > .btn-primary.dropdown-toggle {
  background-color: transparent;
  border-color: var(--orange);
}
.primary-link--dark:active:before {
  background-color: var(--orange);
}

.primary-link--dark:hover {
  border-color: transparent;
  color: #fff;
}

.primary-link--light {
  border-color: #fff;
  color: #fff;
}

.primary-link--light:before {
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#840d81),
    to(#fe6b45)
  );
  background: linear-gradient(90deg, #840d81, #fe6b45);
}

.primary-link--light:active {
  border-color: #d9d9d9;
}

.primary-link--light:active:before {
  background-color: #d9d9d9;
}

.primary-link--light:hover {
  border-color: transparent;
  color: #fff;
}

.primary-link {
  padding: 23px 85px 23px 30px;
  width: 100%;
}

@media (min-width: 768px) {
  .primary-link {
    display: inline-block;
    min-width: 254px;
    width: auto;
    border-radius: 0;
  }
}

.primary-link .svg-icon {
  height: 25px;
  position: absolute;
  right: 30px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  width: 25px;
}

.primary-link--dark .svg-icon {
  color: #ff3700;
}

.primary-link--dark:hover .svg-icon {
  -webkit-animation: primary-link-dark-icon-color-change 0.2s ease-in-out;
  animation: primary-link-dark-icon-color-change 0.2s ease-in-out;
  color: #fff;
}

@keyframes expandWidth {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}

@-webkit-keyframes expandWidth {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}
.primary-link.dropdown-toggle::after {
  display: none;
}
.dropdown-menu.show {
  z-index: 9;
}
.site-btn1 {
  cursor: pointer;
  font-weight: 600;
  font-size: 16px;
  line-height: 32px;
  position: relative;
  color: var(--white);
  display: inline-block;
}
.site-btn1::after {
  position: absolute;
  content: "";
  left: 0;
  bottom: 1px;
  height: 1px;
  width: 100%;
  /* background: var(--white); */
  border: 1px solid var(--white);
  transition: width 0.5s ease;
  display: inline-block;
}
.site-btn1:hover {
  color: var(--white);
}
.site-btn1:hover::after {
  animation: expandWidth 0.5s forwards;
}
a.site-btn1.active {
  display: inline-block;
}
@-webkit-keyframes wtransit {
  0%,
  25% {
    color: #ff3700;
  }

  to {
    color: #fff;
  }
}

@keyframes wtransit {
  0%,
  25% {
    color: #ff3700;
  }

  to {
    color: #fff;
  }
}
select.form-control:focus {
  color: #212529 !important;
  background-color: #fff;
  border-color: #86b7fe;
  outline: 0 !important;
  box-shadow: none !important;
}
.modal-body .form-control {
  margin-bottom: 20px;
  height: auto;
  border-radius: 0;
  min-height: 44px;
}
.modal-body .multiSelectContainer ul {
  width: 100%;
  left: -11px;
  top: 1px;
  position: relative;
  z-index: 999;
  background: var(--white);
}
.modal-body .chip {
  background-color: var(--orange);
}
.modal-footer .primary-link {
  padding-top: 15px;
  padding-bottom: 15px;
  margin: 0;
}
/* .modal-body .multiSelectContainer input {
  background: transparent;
  border: none;
  margin-top: 7px;
} */
/* .multiselect-container.multiSelectContainer.form-control {
  height: auto;
  min-height: 50px;
  margin: 0;
} */
.multiSelectContainer ul li {
  font-size: 12px;
  line-height: 18px;
}
.modal-body .search-wrapper.searchWrapper {
  border: none;
  height: auto;
  padding: 0;
  background-position: right 0px top 10px;
}
select.form-control {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  appearance: auto !important;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.form-label {
  font-size: 14px;
  font-weight: 600;
}
.box {
  opacity: 0;
  transition: opacity 1s ease-in-out;
}
.fade-up {
  transform: translateY(100px);
  transition: transform 1s ease-in-out;
}

.fade-down {
  transform: translateY(-100px);
  transition: transform 1s ease-in-out;
}
.fade-up.fade-in {
  opacity: 1;
  transform: translateY(0);
}

.fade-down.fade-in {
  opacity: 1;
  transform: translateY(0);
}

.nested {
  display: block;
}
.active {
  display: block;
}
.caret1 {
  display: none;
}
.accordion-button::after {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='%23333' xmlns='http://www.w3.org/2000/svg'%3e%3cpath fill-rule='evenodd' d='M8 0a1 1 0 0 1 1 1v6h6a1 1 0 1 1 0 2H9v6a1 1 0 1 1-2 0V9H1a1 1 0 0 1 0-2h6V1a1 1 0 0 1 1-1z' clip-rule='evenodd'/%3e%3c/svg%3e");
  transform: scale(0.7) !important;
}
.accordion-button:not(.collapsed)::after {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='%23333' xmlns='http://www.w3.org/2000/svg'%3e%3cpath fill-rule='evenodd' d='M0 8a1 1 0 0 1 1-1h14a1 1 0 1 1 0 2H1a1 1 0 0 1-1-1z' clip-rule='evenodd'/%3e%3c/svg%3e");
}
/* header section */
.header-section {
  position: relative;
  z-index: 999;
}
.navbar {
  padding: 0;
  width: 100%;
  justify-content: space-between;
}

.header-section .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.6em;
  vertical-align: 0.1em;
  content: "";
  border-top: 0.4em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}
.header-search img {
  cursor: pointer;
  width: 24px;
  height: 24px;
  object-fit: contain;
}

.navbar-collapse {
  opacity: 1;
  transition: 0.5s;
  transition-delay: 0.4s;
}
.inactive.navbar-collapse {
  opacity: 0;
}

.search-form {
  visibility: hidden;
  width: 0;
  opacity: 0;
  margin: 0;
  position: absolute;
  top: -200px;
  transition: 1s;
  z-index: 999;
  left: 53%;
  transform: translateX(-50%);
  width: 75%;
}

.search-form.active {
  top: 20px;
  left: 53%;
  transform: translateX(-50%);
  visibility: visible;
  opacity: 1;
  width: 75%;
}
.search-form form {
  display: flex;
  flex-direction: row-reverse;
  gap: 0 10px;
}
.search-form form input {
  width: calc(100% - 40px);
  padding: 5px;
  font-size: 15px;
  font-weight: 600;
  color: #000;
}
.search-form form input::placeholder{
  color: var(--black);
  font-weight: 400;
  font-size: 16px;
}
.search-form form button {
  background: transparent;
  cursor: none;
}
.nav-btns-sec .close-ic {
  display: none;
}
.nav-btns-sec.active .close-ic {
  display: block;
}

.nav-btns-sec.active .search-ic {
  display: none;
}

.header-search-area input[type="text"] {
  padding: 15px;
  font-size: 17px;
  border: none;
  float: left;
  width: 80%;
  background: var(--white);
}

.header-search-area input[type="text"]:hover {
  background: var(--greylight);
}

.header-search-area button {
  float: left;
  width: 20%;
  padding: 15px;
  background: var(--orange);
  font-size: 17px;
  border: none;
  cursor: pointer;
}

.header-search-area button:hover {
  background: var(--para-text);
}
.navbar-collapse {
  justify-content: space-between;
  align-items: center;
}
.nav-btns-sec {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-left: 50px;
}
/* .nav-btn {
    margin-right: 40px;
} */
.main-navsec {
  padding: 0 0;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 9;
  background: #ffffff;
  box-shadow: 0px 4px 4px 0px #0000001a;
}
.mn-navgrid {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  padding: 5px 0;
}
.navbar-nav {
  gap: 20px;
}
.logosec.navbar-brand {
  padding: 8px 0;
}
a.logosec img {
  max-height: 50px;
  margin-right: 0;
}
.main-navsec .navbar-light .navbar-nav .nav-link {
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  color: var(--black);
}

.main-navsec .navbar-light .navbar-nav .nav-link.active {
  color: var(--orange);
}
.main-navsec .navbar-light .navbar-nav .nav-link:hover {
  color: var(--orange);
}
.menu-intro {
  margin: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid var(--border3);
}
.menu-intro h3 {
  font-size: 20px;
  color: var(--dark-gray);
  font-weight: 600;
  margin-bottom: 5px;
  line-height: 30px;
}
.menu-intro p {
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 0px;
  line-height: 24px;
  color: var(--black);
}
.nav-item .dropdown-menu {
  padding: 0;
  border-radius: 0;
  background-color: var(--white);
  min-width: 300px;
  transition: 0.2s ease-in-out;
  border-radius: 10px;
  top: 45px;
  left: -93px;
  right: auto;
  overflow: hidden;
  border: none;
  border-radius: 0;
  box-shadow: 0px 4px 20px 0px #1d1d1b1a;
  margin: 0 !important;
}
a.dropdown-item {
  font-size: 15px;
  font-weight: 600;
  line-height: 41px;
  letter-spacing: 0;
  color: var(--black) !important;
}
.main-info a.dropdown-item {
  font-size: 13px;
  line-height: 29px;
  white-space: pre-wrap;
}
.dropdown-item.active,
.dropdown-item:hover {
  background-color: var(--grey);
}
/* .nav-item:hover .dropdown-menu {
  top: 60px;
} */

/* .nav-item .dropdown-menu .dropdown-item:hover {
  color: var(--orange) !important;
  background-color: transparent !important;
}
.dropdown-item:active {
  color: var(--orange) !important;
  background-color: transparent !important;
}
.nav-item .dropdown-menu li {
  position: relative;
  overflow: hidden;
}

*/
.nav-item .dropdown-menu li a:hover {
  color: var(--orange);
}

.fixed-top {
  top: -40px;
  transform: translateY(40px);
  transition: transform 0.3s;
  position: fixed;
  z-index: 99999;
  background: var(--white);
  border-bottom: 2px solid var(--orange);
}

.logo-image-fixed {
  display: none;
}
.fixed-top .logo-image-fixed {
  display: block;
}
.fixed-top .logo-image {
  display: none;
}

/* header section end*/

/* Home Banner Section */
.carousel-indicators {
  margin: 0;
  padding: 0 3rem;
  bottom: 93px;
  justify-content: space-between;
  gap: 0 30px;
}
.carousel,
.carousel-inner,
.carousel-item {
  height: 100%;
}

.carousel-indicators::after {
  background: linear-gradient(
    360deg,
    rgba(0, 0, 0, 0.6) 0%,
    rgba(0, 0, 0, 0) 100%
  );
  position: absolute;
  width: 100%;
  height: 115px;
  bottom: -93px;
  content: "";
  left: 0;
  right: 0;
  z-index: 1;
}
.carousel-indicators [data-bs-target] {
  position: relative;
  flex: 1;
  height: 3px;
  border: none;
  border-radius: 24px;
  text-indent: 0;
  opacity: 1;
  z-index: 9;
}

.carousel-indicators [data-bs-target] span {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 100%;
  background: var(--orange);
  border-radius: inherit;
  z-index: 9;
}
.carousel-indicators button {
  position: relative;
  flex: 1;
  height: 3px;
  border: none;
  border-radius: 24px;
  text-indent: 0;
  opacity: 1;
  z-index: 9;
}

.carousel-indicators button span {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 100%;
  background: var(--orange);
  border-radius: inherit;
  z-index: 9;
}
.cartext {
  position: absolute;
  left: 0;
  right: 0;
  text-align: left;
}
.cartext p {
  font-size: 16px;
  line-height: 24px;
  color: var(--white);
  margin: 15px 0 0 0;
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.carousel-item img.main-img {
  object-fit: cover;
  object-position: center;
  width: 100%;
  height: 100%;
}
.carousel-item::after {
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0.4) 0%,
    rgba(0, 0, 0, 0) 100%
  );
  position: absolute;
  content: "";
  left: 0;
  top: 0;
  right: 0;
  min-height: 60%;
}
.landing-banner-item::after {
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0.4) 0%,
    rgba(0, 0, 0, 0) 100%
  );
  position: absolute;
  content: "";
  left: 0;
  top: 0;
  right: 0;
  min-height: 90%;
}
.banner-section {
  height: 90vh;
  overflow: hidden;
}
.landing-banner-item {
  position: relative;
  height: 90vh;
  overflow: hidden;
}

.landing-banner-item img.main-img {
  height: 100%;
  object-fit: cover;
  width: 100%;
}
.banner-contentsec {
  position: absolute;
  top: 21.5%;
  left: 0;
  right: 0;
  /* transform: translateY(-50%); */
  z-index: 9;
}
.landing-banner-item .banner-contentsec {
  top: 20%;
}
.banner-contentsec h1 {
  color: var(--white);
}
.banner-contentsec h2 {
  color: var(--white);
  margin-bottom: 0;
  font-size: 66px;
  font-weight: 600;
  line-height: 82.5px;
  letter-spacing: -0.5px;
}
.banner-contentsec h1{
  margin: 0;
}
.banner-contentsec p {
  font-size: 21px;
  font-weight: 600;
  line-height: 26.2px;
  text-align: left;
  color: var(--white);
  margin-top: 30px;
  overflow: hidden;
   display: -webkit-box;
   -webkit-line-clamp: 10; /* number of lines to show */
           line-clamp: 10; 
   -webkit-box-orient: vertical;
}
a.bnr-btn {
  align-items: center;
  padding: 8px 20px 8px 8px;
  background: var(--white);
  display: inline-flex;
  border-radius: 30px;
  font-size: 20px;
  font-weight: 500;
  color: var(--black);
}
a.bnr-btn span {
  width: 40px;
  height: 40px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background: var(--orange);
  margin-right: 10px;
  transition: 0.5s;
}
a.bnr-btn span img {
  width: 38%;
  height: 38%;
  background-size: contain;
  transition: 0.5s;
}
a.bnr-btn:hover {
  color: var(--white);
  background-color: var(--orange);
}
a.bnr-btn:hover span {
  background-color: var(--white);
  transform: rotate(43deg);
}
a.bnr-btn:hover span img {
  filter: invert(32%) sepia(72%) saturate(4315%) hue-rotate(359deg)
    brightness(98%) contrast(110%);
}

/* added on 10.4.24 for innerpages */
.brdcrm-banner-section {
  padding-top: 59px;
  height: 300px;
  background: linear-gradient(90deg, #840d81 0%, #e05454 45.4%, #fe6b45 100%);
  position: relative;
}
.brdcrm-banner-section.search-brd-crm {
  background: var(--blue);
}
.bread-crmsec {
  position: absolute;
  top: 60%;
  transform: translateY(-50%);
  left: 0;
  right: 0;
  z-index: 9;
}
.main-brdcrmarea ul {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 10px;
}
.main-brdcrmarea ul li {
  position: relative;
  margin-right: 50px;
}
.main-brdcrmarea ul li:last-child::after {
  display: none;
}
.main-brdcrmarea ul li a:first-child {
  display: flex;
  align-items: center;
  color: var(--white);
}

.main-brdcrmarea ul li a:first-child img {
  width: 19px;
  height: 19px;
  object-fit: contain;
  margin-right: 17px;
  margin-top: -2px;
}

.main-brdcrmarea ul li::after {
  content: ">";
  color: var(--white);
  position: absolute;
  right: -30px;
  top: 0;
  transform: scale(1, 1.3);
}
.main-brdcrmarea ul li:last-child::after {
  display: none;
}
.main-brdcrmarea h2 {
  margin: 0;
  color: var(--white);
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
  max-width: 100%;
}
/* Home Banner Section end*/
/* Partners Section start*/
.partner-sl {
  background-color: var(--para-text);
}
.partner-slider .item img {
  height: 20px !important;
  object-position: center;
  filter: brightness(0) invert(1);
  opacity: 0.5;
  transition: 0.5s;
  width: 100%;
}
.partner-slider .item img:hover {
  opacity: 1;
}
.partner-slider .item {
  margin: 0 35px;
}
.partner-slider {
  margin-left: -25px;
  margin-right: -25px;
}
/* Partners Section End*/
/* About Us Section */
.about-us {
  background-color: var(--greylight);
  padding: 64px 0;
}
.video-wrapper {
  height: 450px;
  margin-top: 45px;
  border-radius: 30px;
  position: relative;
  overflow: hidden;
}
.video-wrapper video {
  height: 100%;
  width: 100%;
}
.video-wrapper video[poster] {
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.playButton {
  position: absolute;
  background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 0.74) 8.81%,
    rgba(0, 0, 0, 0) 100%
  );
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99;
}
.playButton img {
  cursor: pointer;
  width: 82px;
  height: 82px;
  object-fit: contain;
}
/* About Us Section End*/

/* info Section Start*/

.info {
  padding: 48px 0;
}
.info:first-child {
  background-color: var(--grey);
}
.info:nth-child(even) {
  padding-top: 110px;
}
.main-img-plot {
  padding-left: 80px;
}
.main-img-plot img {
  width: 100%;
}
.barsnrods .main-img-plot {
  min-height: 450px;
}
span.image-credit {
  display: block;
  width: 100%;
  text-align: center;
  margin: 10px 0;
  font-weight: 600;
  color: var(--black);
  font-style: italic;
}
.info-text ul {
  margin-top: 35px;
}
.info-text ul li p {
  font-size: 16px;
  line-height: 26px;
  margin-bottom: 0;
  margin-top: 10px;
}
.info-text ul li {
  font-size: 25px;
  font-weight: 700;
  line-height: 38px;
  letter-spacing: 0em;
  text-align: left;
  position: relative;
  padding-left: 45px;
  margin-bottom: 15px;
}
.info-text ul li::before {
  content: "";
  position: absolute;
  left: 0;
  top: 10px;
  width: 24px;
  height: 22px;
  background-image: url(../images/pointer-arrow.png);
  background-size: contain;
  background-repeat: no-repeat;
}
.main-img-plot .custom-slick-nav .slick-dots {
  padding: 0;
}
.main-img-plot .custom-slick-nav {
  padding-top: 25px;
}
.sections-common-sec.barsnrods .info:nth-child(1) {
  background-color: var(--white);
}

.sections-common-sec.barsnrods .info:nth-child(2) {
  background-color: var(--grey);
}

.sections-common-sec.barsnrods .info:nth-child(3) {
  background: var(--para-text);
}
.sections-common-sec.barsnrods .info:nth-child(3) .info-text.subtext {
  color: var(--white);
}
.sections-common-sec.barsnrods .info:nth-child(3) .info-text.subtext h3,
.sections-common-sec.barsnrods .info:nth-child(3) .info-text.subtext p,
.sections-common-sec.barsnrods .info:nth-child(3) .info-text.subtext ul li {
  color: var(--white);
}
.sections-common-sec.barsnrods
  .info:nth-child(3)
  .custom-slick-nav
  .slick-prev:before,
.sections-common-sec.barsnrods
  .info:nth-child(3)
  .custom-slick-nav
  .slick-next:before {
  filter: brightness(0) invert(1);
}
.sections-common-sec.barsnrods .info:nth-child(3) .slick-dots li button:before {
  color: var(--white) !important;
}
.sections-common-sec .info-text .btn1 {
  padding: 9px 20px;
  margin-top: 20px;
}

.sections-common-sec .info-text .btn1 img {
  width: 11px;
  margin-left: 10px;
}
.info:nth-child(odd) {
  background-color: var(--grey);
}
.info:nth-child(even) .row {
  flex-direction: row-reverse;
}
.info:nth-child(even) .row .main-img-plot {
  padding-left: 0;
  padding-right: 80px;
}

.info-text h2 {
  margin-bottom: 25px;
}
.info.innr-info {
  background: var(--white);
}
.sustainabilitysec .info-text p {
  margin-bottom: 25px;
}

.sustainabilitysec .info-text p:last-child {
  margin: 0;
}
/* .info-text p {
  margin-bottom: 45px;
} */
.centerbtn {
  text-align: center;
}
.sections-common-sec .info {
  padding-top: 48px;
}
.info.info1 .row {
  flex-direction: row-reverse;
}
.subtext{
  transition: 0.5s;
  height: auto;
}
.subtext.expanded {
  height: 187px;
  overflow: hidden;
}
.subtext p {
  margin: 0 0 15px 0;
  font-size: 16px;
  line-height: 20px;
  color: var(--para-text);
}
.subtext strong {
  display: inline-block;
  color: var(--black);
}
.subtext p strong {
  margin: 0;
}
.main-img-plot .slick-slide img {
  height: auto;
  object-fit: cover;
}
.info-text p a {
  color: var(--para-text);
  font-size: 16px;
  text-decoration: underline;
}
.info-text p a:hover {
  color: var(--orange);
}
.sustainabilitysec .main-info .primary-link {
  margin-top: 30px;
}
.txtbtn{
  cursor: pointer;
}
.sustainabilitysec .info-text a.txtbtn {
  margin-top: 25px;
}
.info-text a {
  color: var(--para-text);
  font-size: 16px;
  text-decoration: underline;

  display: inline-block;
}
.sections-common-sec.barsnrods .info:nth-child(3) a {
  color: var(--white);
}
.info-text a:hover {
  color: var(--orange);
}
.sections-common-sec.barsnrods .info:nth-child(3) a:hover {
  color: var(--orange);
}
.info-text .primary-link {
  display: inline-block;
  margin-top: 25px;
}
.subtext ul li {
  font-size: 16px;
  line-height: 20px;
  font-weight: 400;
  margin: 0;
  color: var(--para-text);
  padding-left: 13px;
  position: relative;
}
.subtext ul li a {
  font-size: 16px;
  line-height: 20px;
  font-weight: 400;
  margin: 0;
  color: var(--para-text);
  position: relative;
}
.subtext ul li span {
  color: var(--orange);
  text-decoration: underline;
}
.subtext ul li::before {
  display: inline-block;
  width: 6px;
  height: 6px;
  background-image: none;
  background-color: var(--orange);
  border-radius: 50px;
  top: 7px;
  left: 0;
  position: absolute;
  content: "";
}
.fencingVineyard-cont .grd.grd1 {
  background: var(--grey);
}
.fencingVineyard-cont .grd2 .subtext ul li {
  margin-bottom: 7px;
}
.fencingVineyard-cont .grd2 .subtext ul li a {
  color: var(--white);
  display: inline-block;
}

.fencingVineyard-cont .grd2 .subtext ul li:before {
  background: var(--white);
}

.fencingVineyard-cont .grd.grd1 h3 {
  color: var(--black);
}
.subtext ul {
  margin-top: 15px;
  margin-bottom: 15px;
}
/* info Section End*/

/* Our Industries Section*/

.our-industries {
  padding: 64px 0;
  position: relative;
  background: var(--lightblack);
}
.industries-head h2 {
  color: var(--white);
}

.industries-head p {
  color: var(--white);
}

.industries-head {
  margin-bottom: 50px;
}
.ovrly {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: max-content;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding: 30px;
  background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.3) 100%
  );
}

.ovrly-content {
  width: 100%;
  transition: 0.5s;
  position: relative;
  z-index: 9;
}

.main-indus {
  display: block;
  width: 100%;
  border-radius: 30px;
  overflow: hidden;
  height: 500px;
  position: relative;
}

.main-indus img {
  height: 100%;
  object-fit: cover;
  width: 100%;
}
.ovrly-content p {
  font-weight: 600;
  font-size: 25px;
  max-width: calc(100% - 40px);
  color: var(--white);
}
.main-indus:hover .ovrly-content {
  margin-bottom: 10px;
}

.main-newslettergrid .img-main {
  position: absolute;
  height: 101%;
  top: 0;
  right: -5%;
  width: 490px;
  object-fit: contain;
  opacity: 0.4;
}
/* .main-newsform form {
  display: flex;
  justify-content: space-between;
  align-items: center;
} */

/* .main-newsform a {
  width: 113px;
  height: 44px;
  padding: 10px 20px 10px 20px;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;
  background-color: var(--orange);
  color: var(--white);
  border-radius: 8px;
  display: inline-block;
  margin-top: 20px;
} */

.main-newsform form input {
  width: calc(100% - 120px);
  padding: 10px 8px 10px 12px;
  border-radius: 8px;
  font-weight: 500;
  font-size: 16px;
  color: var(--para-text);
}
.main-newsform form input::placeholder {
  font-weight: 500;
  font-size: 16px;
  color: var(--para-text);
}

.main-industries {
  position: relative;
  z-index: 1;
}
.main-industries::before {
  position: absolute;
  content: "";
  right: -40px;
  top: -90px;
  width: 200px;
  height: 200px;
  background-size: contain;
  background-image: url(../images/dotted1.svg);
  z-index: -1;
}

/* Our Industries End*/

/* Latest News Section*/
.latest-news {
  padding: 48px 0;
}
.news-head {
  margin-bottom: 40px;
}
.blogpage .main-nw {
  margin-bottom: 25px;
}
.blog-details-sec {
  padding: 48px 0;
}
.blog-header p {
  font-size: 21px;
  line-height: 26.2px;
  font-weight: 600;
  margin-bottom: 20px;
}
.blog-img img {
  width: 100%;
  margin: 10px 0 20px 0;
}
.blog-text p {
  margin-bottom: 20px;
}
.blog-text p:last-child {
  margin: 0;
}
.nws {
  border-radius: 0 0 0 0;
  border: 1px solid var(--border3);
  padding: 25px;
  transition: 0.5s;
}
.main-nw {
  display: block;
  width: 100%;
  position: relative;
  border-radius: 0;
  transition: 0.5s;
}
.main-nw:hover {
  box-shadow: 0px 4px 15px 0px #00000033;
}
.nws-img {
  overflow: hidden;
  height: auto;
  border-radius: 0 0;
}
.main-nw .nws-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: top center;
  transition: 0.8s;
}
.main-nw:hover .nws-img img {
  transform: scale(1.1);
}
.main-news .item {
  margin: 15px;
}

.news-slider {
  margin: 0 -15px;
}
.main-nw:hover .nws {
  background-color: var(--white);
}
.nws-date {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-bottom: 10px;
}
.nws-date img {
  width: 20px;
  height: 19px;
  object-fit: contain;
  margin-right: 10px;
}
.nws-date p {
  font-weight: 500;
  font-size: 16px;
  color: var(--orange);
  line-height: 20px;
}
.nws-content p {
  font-size: 21px;
  line-height: 26.2px;
  font-weight: 400;
  width: 100%;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  color: var(--black);
  margin-bottom: 10px;
  min-height: 52px;
}
.nws-content a,
.nws-content a:hover {
  color: var(--black);
}
.nws-content a::after {
  border:1px solid var(--black);
}
.view-more-btnsec {
  text-align: center;
  margin-top: 45px;
}
.view-more-btnsec button {
  background: var(--white);
}
/* .latest-news .slick-prev, .latest-news .slick-next {
  top: -99px;
  transform: none;
  left: auto;
  right: 24px;
  width: 45px;
  height: 45px;
  border-radius: 50%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  background-color: var(--orange);
}
.latest-news .slick-prev {
  right: 78px;
}
.latest-news .slick-prev {
  background-color: var(--white);
}
.latest-news .slick-prev:hover {
  background-color: var(--orange);
}
.latest-news .slick-prev:before {
  content: "\003C";
}
.latest-news .slick-next:before {
  content: "\003E";
}
.latest-news .slick-prev:before, .latest-news .slick-next:before {
  font-family: "Gilroy";
  font-weight: 400;
  font-size: 15px;
  color: var(--border3);
  opacity: 1;
  -webkit-transform: scale(1.3, 2);
  -moz-transform: scale(1.3, 2);
  -o-transform: scale(1.3, 2);
  transform: scale(1, 1.7);
}
.latest-news .slick-prev::after {
  position: absolute;
  content: "";
  border: 1px solid var(--border3);
  width: 118px;
  height: 60px;
  right: -64px;
  top: -7px;
  border-radius: 30px;
} */

.latest-news .slick-dots li button:before {
  opacity: 1;
}
.latest-news .slick-list {
  position: relative;
  display: block;
  overflow: visible;
  clip-path: inset(-3rem -3rem -3rem 0);
  margin: 0;
  padding: 0;
}

/* Latest News Section End*/
/* Newsletter */
.hm-newsletter {
  padding: 51px 0;
  background-color: var(--grey);
}
.news-form {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 25px;
}
.nws-wrapper {
  position: relative;
  z-index: 1;
}
.modal-footer {
  justify-content: flex-start;
  border: none;
  padding: 0;
}
svg.progress-svg {
  position: absolute;
  left: 0;
  top: 0px;
  display: none;
}

.slick-active svg.progress-svg {
  display: inline-block;
  width: 25px;
  height: 25px;
}
.circle-bg {
  fill: none;
  stroke: rgba(0, 0, 0, 0);
  stroke-width: 2;
}

.circle-go {
  fill: none;
  stroke: var(--orange);
  stroke-width: 2;
  stroke-linecap: round;
  stroke-dasharray: 120; /* Adjust based on the circumference of the circle */
  stroke-dashoffset: 120; /* Adjust based on the circumference of the circle */
  animation: fillCircle 10s linear forwards;
}

@keyframes fillCircle {
  from {
    stroke-dashoffset: 120; /* Adjust based on the circumference of the circle */
  }
  to {
    stroke-dashoffset: 0;
  }
}
.main-news {
  position: relative;
}

.custom-slick-nav {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 100%;
}
.custom-slick-nav .slick-prev,
.custom-slick-nav .slick-next {
  left: auto;
  right: auto;
  position: relative;
  transform: none;
  top: 2px;
  opacity: 1;
}
.custom-slick-nav .slick-prev:before,
.custom-slick-nav .slick-next:before {
  content: "";
  background: url(../images/nav-icon.svg);
  width: 8px;
  height: 14px;
  display: inline-block;
  background-repeat: no-repeat;
  background-size: contain;
  opacity: 1;
}
.custom-slick-nav .slick-prev {
  order: 1;
}
.custom-slick-nav .slick-next {
  order: 3;
}
.custom-slick-nav .slick-dots {
  position: relative;
  bottom: -2px;
  display: flex;
  align-items: center;
  width: auto;
  order: 2;
  padding: 40px 0;
}
.custom-slick-nav .slick-next:before {
  transform: rotateY(180deg);
}

/* Newsletter End*/
/* Fullpage carousel Section */
.full-slsec {
  padding: 0;
}
.full-slsec .slick-dotted {
  margin: 0;
}
.single-srv-grid {
  position: relative;
  margin-top: 0;
  display: block;
  overflow: hidden;
}
.slick-slide div {
  line-height: 0;
}
.flat-bline .srv-slider .slick-slide {
  height: auto;
}
.srv-slider .slick-slide {
  height: auto;
}
.srv-slider .slick-slide div {
  height: 100%;
}
.srv-slider .slick-slide div.ovrly {
  height: max-content;
}
.full-slsec.stshape-bline .srv-slider .slick-slide {
  height: auto;
}
.full-slsec.wiresol-bline .srv-slider .slick-slide {
  height: auto;
}
.full-slsec.logistic-bline .srv-slider .slick-slide {
  height: auto;
}
.logistic-bline .ovrly-content .site-btn1 {
  display: none;
}
.full-slsec.barrods-bline .srv-slider .slick-slide {
  height: auto;
}
.single-srv-grid .mn-im {
  overflow: hidden;
  height: 100%;
}

.single-srv-grid img.srv-main {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: 0.8s;
}
.single-srv-grid .ovrly::after {
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  content: "";
  opacity: 0;
  position: absolute;
  transition: 0.5s;
  z-index: 1;
}

.single-srv-grid:hover .ovrly::after {
  opacity: 1;
  background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.5) 100%
  );
}
.single-srv-grid:hover img.srv-main {
  transform: scale(1.1);
}
.count {
  position: absolute;
  font-size: 150px;
  line-height: 150px;
  font-weight: 600;
  left: 50%;
  transform: translateX(-50%);
  top: -73px;
  z-index: 9;
}
.single-srv-grid .ovrly-content p {
  font-size: 28px;
  line-height: 35px;
  margin-bottom: 15px;
  max-width: 100%;
}
.srv-head {
  text-align: center;
  margin-bottom: 40px;
}

.count {
  color: transparent;
  text-shadow: 1px 1px var(--border3), -1px -1px var(--border3);
}
@supports (-webkit-text-stroke: 1px var(--border3)) {
  .count {
    color: transparent;
    -webkit-text-stroke: 4px var(--border3);
    text-shadow: none;
  }
}
/* Fullpage carousel Section */

/* Table sec Section */
.fl-tablesec {
  padding: 48px 0;
  background: var(--grey);
}

.fl-tablesec h3 {
  text-align: center;
}

.main-tablearea {
  background: var(--white);
}
.search-filter {
  padding: 25px 20px;
  display: flex;
  align-items: flex-start;
}

.search-filter select {
  min-width: 250px;
  outline: none !important;
  border: 1px solid var(--black);
  padding: 15px;
  font-size: 16px;
  line-height: 20px;
  height: 56px;
  -webkit-appearance: none;
  background-image: url(../images/drop.svg);
  background-position: right 14px center;
  background-repeat: no-repeat;
}
.search-wrapper.searchWrapper {
  min-width: 250px;
  outline: none !important;
  border: 1px solid var(--black);
  padding: 3px 15px;
  font-size: 16px;
  line-height: 20px;
  border-radius: 0;
  border-left: none;
  height: 56px;
  overflow-y: auto;
  background-image: url(../images/drop.svg);
  background-position: right 14px center;
  background-repeat: no-repeat;
}
.main-tablearea .multiSelectContainer input {
  margin-top: 15px;
}
.main-tablearea .chip {
  margin-bottom: 0;
  background: var(--orange) !important;
}
.highlightOption {
  background: var(--orange) !important;
}
.multiSelectContainer li:hover {
  background: var(--orange) !important;
}
/* .search-filter select:first-child{
  border-right: none;
} */
.no-category-sel {
  padding: 10px 20px;
  min-height: 50px;
}
.no-category-sel p {
  font-weight: 600;
}
.no-category-sel p span {
  color: var(--orange);
}
.mn-table table {
  width: 100%;
}
.mn-table table th {
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  padding: 10px 12px;
  text-align: center;
}
.mn-table thead tr:nth-child(1) th:nth-child(2) {
  text-align: right;
}
/* .mn-table thead tr:nth-child(1) th:nth-child(1) {
  text-align: left;
} */
.mn-table td img {
  width: 17px;
  display: block;
  margin: 0 auto;
}
.mn-table table tr td {
  padding: 10px 15px;
  font-size: 16px;
  line-height: 20px;
  color: var(--black);
  font-weight: 400;
  text-align: center;
}
p.table-info-dt {
  border-top: 1px solid var(--black);
  padding: 15px;
}
.mn-table table tbody tr:nth-child(odd) {
  background: var(--greyshade2);
}
.mn-table table tr {
  border-top: 1px solid var(--black);
}
/* .mn-table table tbody tr td:nth-child(1) {
  text-align: left;
} */
.mn-table table tbody tr td:nth-child(2) {
  text-align: right;
}
.a93-pgtable.mn-table table tbody tr td:nth-child(2) {
  text-align: center;
}
.a93-pgtable.mn-table thead tr:nth-child(1) th:nth-child(2) {
  text-align: center;
}
.info-multi .row {
  margin: 0 0 15px 0;
  --bs-gutter-x: 0;
}
.wo-imgsec .main-img-plot {
  min-height: inherit;
}
.info-multi .row:nth-child(even) {
  flex-direction: row-reverse;
}

.info-multi .main-img-plot {
  padding: 0;
}
.info-multi .info-text {
  padding-right: 40px;
}
.info-multi .info-text p {
  margin-bottom: 10px;
}
.info-multi .row:nth-child(even) .main-info {
  padding-right: 0px;
  padding-left: 40px;
}
.mn-table table tbody tr td:nth-child(8),
.mn-table table tbody tr td:nth-child(9),
.mn-table table tbody tr td:nth-child(10),
.mn-table table tbody tr td:nth-child(11),
.mn-table table tbody tr td:nth-child(12),
.mn-table table tbody tr td:nth-child(13) {
  text-align: center;
}

/* Table sec Section End*/

/* Contact Us Section */
.contact-us {
  padding: 48px 0;
  scroll-margin-top: 50px;
}
.accordion-item {
  border-top: 1px solid var(--para-text);
  border-bottom: 1px solid var(--para-text);
  border-left: none;
  border-right: none;
  border-radius: 0;
}
.accordion-item:first-of-type {
  border-radius: 0 !important;
}
.accordion-item:last-of-type {
  border-radius: 0 !important;
}
.accordion-button {
  font-weight: 600;
  font-size: 21px;
  line-height: 26.2px;
  color: var(--black);
  background: var(--white);
  padding-left: 0;
  padding-right: 0;
}
.accordion-button::after {
  opacity: 1 !important;
}
.accordion-button:not(.collapsed) {
  color: var(--black);
  background: transparent;
  /* border-bottom: 1px solid var(--border3); */
}
.accordion-button::after {
  opacity: 0.5;
}
.slick-dots li button:before {
  opacity: 1 !important;
  color: var(--black) !important;
}
.accordion-button:not(.collapsed)::after {
  opacity: 1;
}
.accordion-body {
  padding: 0;
}
.contdet-row {
  padding: 1rem 0;
  border-bottom: 1px solid var(--border3);
}

.steelFibreSec .info .row.align-items-center {
  align-items: flex-start !important;
}

.steelFibre .info .main-img-plot {
  min-height: inherit;
}
.cdhead {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.cdhead img {
  width: 24px;
  height: 24px;
  margin-left: 15px;
  border-radius: 50%;
  object-fit: cover;
}
.cdhead p {
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: var(--black);
}

.cdbody ul {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.cdbody ul li {
  display: flex;
  align-items: center;
  margin-right: 25px;
}

.cdbody ul li p,
.cdbody ul li a {
  font-size: 16px;
  line-height: 20px;
  font-weight: 400;
  color: var(--para-text);
}

.cdbody ul li img {
  max-height: 16px;
  margin-right: 5px;
}
.accordion-item {
  background-color: var(--fadedwhite);
}

/* Contact Us Section End*/

/* Map Sec */

.logistic-bline .ovrly-content .site-btn1 {
  display: none;
}

.mapsec {
  padding: 64px 0 0 0;
}
/* Map Sec End*/

/* START Structure gall */
.st-gallsec {
  padding: 48px 0;
}
.gall-rowsec {
  margin: 25px 0;
}
.gall-rowsec .info-text a.txtbtn {
  margin-bottom: 25px;
}
.st-gallsec h3 {
  margin-bottom: 35px;
}
/* Structure gall end*/
/* START grids */
section.gridsec {
  display: flex;
}
.grd {
  height: auto;
  padding: 50px 90px;
  flex: 1 1;
}
.grd h3,
.grd p {
  color: var(--white);
}
.grd p a {
  text-decoration: underline;
  color: var(--white);
}
.grd.grd1 {
  background: var(--para-text);
}

.grd.grd2 {
  background: var(--blue);
}
/* END grids */
/* START Fl-tablesec */

/* END Fl-tablesec */
/* Footer Section */
footer.ft-sec {
  background: var(--dark-gray);
  box-shadow: 0px -15px 40px 0px #0000000f;
}
.upr-mn-ft {
  padding: 48px 0 0 0;
}

.upr-mn-ft h2 {
  font-weight: 600;
  font-size: 30px;
  color: var(--white);
  margin-bottom: 25px;
}
.sn-ftr-ql ul li {
  position: relative;
  margin-bottom: 14px;
}
.sn-ftr-ql ul li:last-child {
  margin: 0;
}
.sn-ftr-ql.foot-social ul li {
  padding: 0;
}
.sn-ftr-ql.foot-social li a {
  display: flex;
  align-items: center;
}
.sn-ftr-ql.foot-social ul li a::after {
  display: none;
}
.sn-ftr-ql.foot-social ul li img {
  max-width: 13px;
  margin-right: 9px;
  filter: brightness(100%) invert(1);
}
.upr-ftr .col-md-2:nth-child(2) {
  width: 13.666667%;
}
.upr-ftr .col-md-2:nth-child(3) {
  width: 19.66666667%;
}
.sn-ftr-ql ul li a {
  font-weight: 400;
  font-size: 14px;
  line-height: 17.5px;
  position: relative;
  color: var(--white);
  display: inline-block;
}
.sn-ftr-ql ul li a::after {
  position: absolute;
  content: "";
  left: 0;
  bottom: -2px;
  height: 1px;
  width: 100%;
  /* background: var(--white); */
  border: 1px solid var(--white);
  transition: width 0.5s ease;
}
.sn-ftr-ql ul li a:hover {
  color: var(--white);
}
.sn-ftr-ql ul li a:hover::after {
  animation: expandWidth 0.5s forwards;
}
.upr-ftr {
  padding-bottom: 35px;
  margin-bottom: 0;
  border-bottom: 1px solid var(--border2);
}
.upr-ftr h3 {
  font-size: 16px;
  font-weight: 600;
  color: var(--white);
  line-height: 17px;
  margin-bottom: 20px;
}
.copyright-area {
  padding: 20px 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.copyright-area p {
  color: var(--fadedwhite);
  font-size: 14px;
  margin: 0;
  line-height: 25px;
}
.copyright-area p a {
  color: var(--white);
}
.copyright-area p a:hover {
  text-decoration: underline;
}
.copyright-area ul {
  display: flex;
  align-items: center;
}
.copyright-area ul li a {
  color: var(--fadedwhite);
  font-size: 14px;
  line-height: 25px;
  position: relative;
  margin-left: 43px;
  transition: 0s;
}
.copyright-area ul li a:hover {
  color: var(--orange);
}
.copyright-area ul li a::before {
  left: -24px;
  top: 5px;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  z-index: 99;
  position: absolute;
  content: "";
  background: var(--fadedwhite);
}
.copyright-area ul li:first-child a::before {
  display: none;
}
/* Footer Section End*/

/* Responsive  Css */
@media screen and (min-width: 2201px) {
  .container-fluid.px-5 {
    padding: 0px 15px !important;
    width: 1234px;
  }
  .nws-img {
    height: auto;
  }
}
@media screen and (min-width: 1901px) and (max-width: 2200px) {
  .container-fluid.px-5 {
    padding: 0px 15px !important;
    width: 1234px;
  }
  /* .nws-img {
    height: 62vh;
  } */
}
@media screen and (min-width: 1501px) and (max-width: 1900px) {
  .container-fluid.px-5 {
    padding: 0 15px !important;
    width: 1234px !important;
  }
  .logosec.navbar-brand {
    padding: 9.8px 0;
  }
  .header-search img {
    width: 20px;
    height: 20px;
  }
  /* .nws-img {
    height: 57vh;
  } */
  .row > * {
    padding-right: calc(var(--bs-gutter-x) * 0.6);
    padding-left: calc(var(--bs-gutter-x) * 0.6);
  }
}
@media screen and (min-width: 1351px) and (max-width: 1500px) {
  h1 {
    margin: 0;
  }
  .container-fluid.px-5 {
    padding: 0 15px !important;
    width: 1234px !important;
  }
  .logosec.navbar-brand {
    padding: 9.8px 0;
  }
  .header-search img {
    width: 20px;
    height: 20px;
  }
  .landing-banner-item .banner-contentsec {
    top: 20%;
  }
  .banner-contentsec {
    top: 21.5%;
  }
  .banner-contentsec p {
    -webkit-line-clamp: 15;
}
  .nws-img {
    height: auto;
  }

}

@media screen and (min-width: 1200px) and (max-width: 1350.5px) {
  /* Common */
  h1 {
    font-size: 60px;
    font-weight: 600;
    line-height: 77.5px;
    letter-spacing: -0.5px;
    margin: 0;
  }
  .banner-contentsec p {
    font-size: 21px;
    line-height: 26.2px;
    -webkit-line-clamp: 14;
}
.banner-contentsec h2 {
  font-size: 60px;
  font-weight: 600;
  letter-spacing: -.5px;
  line-height: 77.5px;
}
  .landing-banner-item .banner-contentsec {
    top: 22%;
  }
  .banner-contentsec {
    top: 21.5%;
  }
  .single-srv-grid .ovrly-content p {
    font-size: 22px;
    line-height: 27px;
  }
  .nws-img {
    overflow: hidden;
    height: auto;
    border-radius: 0 0;
  }
  .site-btn1 {
    font-size: 14px;
    line-height: 28px;
  }
  /* Common End*/
  .main-navsec .navbar-light .navbar-nav .nav-link {
    font-size: 15px;
    line-height: 19px;
  }
  .news-grids::after {
    right: 0;
  }

  .copyright-area ul li a {
    font-size: 13px;
  }
}

@media screen and (min-width: 992px) and (max-width: 1199.5px) {
  /* Common */

  /* Common End*/
  /* Header */
  .nav-link {
    margin: 0;
  }
  .navbar-nav {
    gap: 2px;
  }
  a.logosec img {
    width: 97px;
    margin-right: 15px;
  }

  .main-navsec .navbar-light .navbar-nav .nav-link {
    font-size: 15px;
    font-weight: 600;
    line-height: 20px;
    color: var(--black);
  }
  /*Header End*/
  /*Banner Section*/
  .banner-contentsec .col-md-8 {
    width: 100%;
  }
  .landing-banner-item .banner-contentsec {
    top: 20%;
  }
  .landing-banner-item .banner-contentsec p {
    -webkit-line-clamp: 15;
}
  /*Banner Section End*/
  /*Latest News */
  .news-grids::after {
    right: 0;
  }
  .latest-news {
    padding: 48px 0;
  }
  .nws-img {
    overflow: hidden;
    height: auto;
    border-radius: 0 0;
  }
  .srv-slider .slick-slide {
    height: auto !important;
  }
  /*Footer */
  .upr-ftr .col-md-2 {
    flex: 0 0 33.33%;
    max-width: 33.33%;
    margin-top: 20px;
  }
  .sn-ftr-ql {
    margin-bottom: 20px;
  }
  /*Footer End*/
}

@media screen and (min-width: 768px) and (max-width: 991.5px) {
  /* Common */

  .container-fluid.px-5 {
    padding: 0 30px !important;
  }
  /* Common End*/
  /* Header */

  .srv-slider .slick-slide {
    height: auto !important;
  }
  .navbar {
    padding: 0;
    width: 100%;
  }
  .navbar-toggler {
    padding: 0;
    border: none !important;
    outline: none !important;
    margin-top: 19px;
    margin-bottom: 17px;
    position: absolute;
    right: 0;
  }
  .inactive.navbar-toggler {
    display: none;
  }
  .logosec.inactive.navbar-brand {
    opacity: 0;
  }
  .search-form.active {
    left: 0;
    transform: none;
    width: 92%;
  }
  .navbar-collapse {
    background: var(--white);
    position: fixed;
    left: 0;
    right: 0;
    top: 65px;
    height: 100vh;
    transition-delay: 0s;
  }
  a.dropdown-item {
    padding-left: 28px;
  }
  .main-navsec .navbar-light .navbar-nav .nav-link {
    font-size: 17px;
    font-weight: 600;
    line-height: 40px;
    letter-spacing: 0;
    padding: 0.5rem 1rem;
    border-bottom: 1px solid var(--grey);
  }
  .nav-btns-sec {
    position: absolute;
    right: 45px;
  }
  .nav-btns-sec.active {
    right: 0;
    margin: 0;
  }
  .navbar-nav {
    gap: 0px;
    height: calc(100vh - 90px);
    overflow-y: auto;
  }
  /* Header End*/
  /* Banner */
  .bnr-para {
    font-size: 16px;
    line-height: 24px;
  }
  .banner-contentsec .col-md-8 {
    width: 100%;
  }
  .landing-banner-item .banner-contentsec {
    top: 20%;
  }
  .landing-banner-item .banner-contentsec p {
    -webkit-line-clamp: 22;
}

  /* Banner End*/

  /* What we do*/
  .single-wedo-grid p {
    font-size: 17px;
    line-height: 30px;
    margin-top: 20px;
  }
  /* What we do End*/
  /* bars rods*/
  .main-img-plot {
    padding: 0 !important;
  }
  /* bars rods End*/

  /* Latest News*/
  .news-grids::after {
    right: 0;
  }
  .single-news-text p {
    font-size: 17px;
    line-height: 25px;
  }
  .main-newsform {
    width: 100%;
    margin-top: 30px;
  }
  .nws-img {
    overflow: hidden;
    height: auto;
    border-radius: 0 0;
  }
  .nws {
    padding: 15px;
  }
  /* Latest News End*/

  /* contact*/
  .cdbody ul {
    flex-wrap: wrap;
    gap: 10px;
  }

  .cdbody ul li {
    width: 100%;
  }
  /* contact End*/
  /* info */
  .latest-news {
    padding-top: 15px;
  }
  /* info end */
  /* Footer*/
  .upr-ftr .col-md-2 {
    flex: 0 0 33.33%;
    max-width: 33.33%;
    margin-top: 20px;
  }
  .sn-ftr-ql ul li a {
    font-size: 13px;
  }
  .upr-mn-ft {
    padding: 40px 0 0 0;
  }
  .sn-ftr-ql {
    margin-bottom: 20px;
  }
  /* Footer End*/
}

@media screen and (max-width: 767.5px) {
  /* Common */
  h1 {
    font-size: 11.5vw;
    word-break: break-word;
    line-height: 1.1;
    margin: 0;
  }
  h2 {
    font-weight: 600;
    font-size: 35px;
    line-height: 40.5px;
    color: var(--black);
  }
  .banner-contentsec h2 {
    font-size: 11.5vw;
    word-break: break-word;
    line-height: 1.1;
    }
  p {
    font-size: 16px;
    line-height: 24px;
  }
  .carousel-indicators {
    display: none;
  }
  .banner-contentsec {
    top: 26%;
  }
  .container-fluid.px-5 {
    padding: 0 15px !important;
  }
  /* Common End*/
  /* Header */
  .navbar {
    padding: 0;
    width: 100%;
  }
  .navbar-toggler {
    padding: 0;
    border: none !important;
    outline: none !important;
    margin-top: 19px;
    margin-bottom: 17px;
    position: absolute;
    right: 0;
  }
  .inactive.navbar-toggler {
    display: none;
  }
  .logosec.inactive.navbar-brand {
    opacity: 0;
  }
  .search-form.active {
    left: 0;
    transform: none;
    width: 89%;
  }
  .navbar-collapse {
    background: var(--white);
    position: absolute;
    left: -15px;
    right: -15px;
    top: 65px;
    height: 100vh;
    transition-delay: 0s;
  }

  a.dropdown-item {
    padding-left: 28px;
  }
  .main-navsec .navbar-light .navbar-nav .nav-link {
    font-size: 17px;
    font-weight: 600;
    line-height: 40px;
    letter-spacing: 0;
    padding: 0.5rem 1rem;
    border-bottom: 1px solid var(--grey);
  }
  .nav-btns-sec {
    position: absolute;
    right: 45px;
  }
  .nav-btns-sec.active {
    right: 0;
    margin: 0;
  }
  .navbar-nav {
    gap: 0px;
    height: calc(100vh - 90px);
    overflow-y: auto;
  }
  .main-brdcrmarea h2 {
    margin: 10px 0 0 0;
    color: var(--white);
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    line-clamp: 3;
    -webkit-box-orient: vertical;
    max-width: 100%;
}
  /* Header End*/
  /* Banner */
  .landing-banner-item .banner-contentsec {
    top: 20%;
  }
  .banner-contentsec p {
    font-size: 16px;
    line-height: 21px;
    margin: 15px 0 0 0;
    -webkit-line-clamp: 18;
}
  .carousel-item img.main-img {
    object-position: center center;
  }
  /* Banner End*/

  /* Business Line*/
  .view-more-btnsec.wiresol-btn {
    margin-top: 40px;
  }
  button.slick-arrow.slick-prev {
    display: none !important;
  }

  button.slick-arrow.slick-next {
    display: none !important;
  }
  /* Business Line End*/
  /* Latest News*/
  .main-nw {
    margin-bottom: 30px;
  }
  .srv-slider .slick-slide {
    height: auto;
  }

  /* Latest News*/
  .news-slide .owl-dots {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 15px;
  }
  .news-slide .owl-dot {
    width: 8px;
    height: 8px;
    background: var(--white);
    border-radius: 50%;
    margin: 0 5px;
  }
  .news-slide .owl-dot.active {
    background: var(--orange);
  }
  .single-news-image img {
    width: 100%;
    height: auto;
    object-fit: cover;
  }
  .nws-img {
    height: auto;
  }
  .view-more-btnsec {
    text-align: center;
    margin-top: 0;
  }
  h3.orange-heading-text {
    font-size: 20px;
    line-height: 34px;
  }
  .single-news-text p {
    font-size: 18px !important;
    line-height: 24px;
    font-weight: 400;
  }
  .single-news-text {
    position: relative;
    padding: 20px;
    text-align: center;
  }
  .single-news-text a {
    position: relative;
    right: auto;
    bottom: auto;
    margin-top: 20px;
  }
  .news-grids::after {
    right: 0;
  }
  .latest-news {
    padding: 48px 0;
  }
  .latest-news.blogpage {
    padding: 48px 0;
  }
  /* Latest News End*/

  /* Newsletter*/
  .nws-frm-text p br {
    display: none;
  }
  .main-newsform {
    width: 100%;
    margin-top: 30px;
  }
  /* Newsletter End*/

  /* Contact*/
  .cdbody ul {
    flex-wrap: wrap;
    gap: 9px 0;
  }
  .srv-head {
    text-align: center;
    margin-bottom: 0;
  }
  /* Contact End*/
  /*logistics*/
  .gridsec {
    flex-wrap: wrap;
  }
  .grd {
    width: 100%;
    height: auto;
    padding: 25px;
    flex: 0 0 100%;
    min-height: initial;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }
  .logistics-cont .grd h3 {
    display: none;
  }
  .mapboxgl-map {
    height: 60vh !important;
  }
  /*logistics end*/
  /* info Sec*/
  .main-img-plot {
    padding: 0;
    margin-top: 30px;
  }
  .info:nth-child(even) .row .main-img-plot {
    padding-left: 0;
    padding-right: 0;
  }
  .view-more-btnsec.wiresol-btn .primary-link {
    font-size: 16px;
  }
  /* info Sec End*/
  .search-filter {
    flex-direction: column;
    gap: 11px;
  }

  .search-filter select {
    width: 100%;
  }

  .search-filter div {
    width: 100%;
  }

  .search-wrapper.searchWrapper {
    border-left: 1px solid var(--black);
  }
  /* Footer*/
  .toggle-heading {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 15px;
    padding-bottom: 15px;
    border-bottom: 1px solid var(--border2);
  }
  .caret1 {
    display: block;
  }
  .nested {
    display: none;
    padding-top: 15px;
  }
  .nested.active {
    display: block;
  }
  .upr-ftr .col-md-2:nth-child(2),
  .upr-ftr .col-md-2:nth-child(3) {
    width: 100%;
  }
  .upr-ftr h3 {
    margin: 0;
  }
  .caret1 .open {
    width: 20px;
    height: 20px;
    object-fit: contain;
    filter: brightness(0) invert(1);
  }
  .caret1 .close {
    width: 15px;
    height: 15px;
    object-fit: contain;
    filter: brightness(0) invert(1);
    display: none;
  }
  .toggle-heading.caret-down .open {
    display: none;
  }

  .toggle-heading.caret-down .close {
    display: block;
  }
  .copyright-area {
    padding: 30px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
  }
  .upr-ftr {
    border-bottom: none;
  }

  .copyright-area ul li a {
    color: var(--white);
    font-size: 14px;
    line-height: 25px;
    position: relative;
    margin: 0 15px;
    transition: 0s;
  }
  .copyright-area ul {
    margin-top: 20px;
  }
  .copyright-area ul li:last-child a::before {
    left: -18px;
  }
  /* Footer End*/
  /* .custom-slick-nav {
    display: none;
  } */
  .main-tablearea .chip {
    margin-bottom: 6px;
  }
}

@media screen and (min-width: 576px) and (max-width: 767.5px) {
}

/* styles.css */

.marker {
  background-image: url(../images/marker.svg);
  background-size: contain;
  width: 25px;
  height: 35px;
  background-repeat: no-repeat;
  cursor: pointer;
}

.marker.hover::before {
  content: "";
  position: absolute;
  top: -20px;
  left: -20px;
  background-color: white;
  padding: 5px;
  border: 1px solid black;
}

.custom-popup.mapboxgl-popup-anchor-left .mapboxgl-popup-tip {
  border-right-color: var(--orange); /* Set the color of the arrow */
}

.custom-popup.mapboxgl-popup-anchor-left .mapboxgl-popup-content {
  border-left: 4px solid var(--orange);
}

.custom-popup.mapboxgl-popup-anchor-left .mapboxgl-popup-tip::before {
  border-left-color: var(--orange); /* Set the color of the arrow */
}
.custom-popup {
  filter: drop-shadow(1px 1px 15px #dddddd);
}
.custom-popup p {
  font-size: 16px;
  font-weight: 600;
  color: var(--black);
  margin: 0;
}

.loading-animation {
  background-color: var(--para-text);
  position: fixed;
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
}

/* added by raunak */
/* body{
  padding-top: 0px !important;
} */

/* loader */
.loader {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: inline-block;
  border-top: 4px solid var(--white);
  border-right: 4px solid transparent;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}
.loader::after {
  content: "";
  box-sizing: border-box;
  position: absolute;
  left: 0;
  top: 0;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border-left: 4px solid var(--orange);
  border-bottom: 4px solid transparent;
  animation: rotation 0.5s linear infinite reverse;
}
@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.PhoneInput {
  display: flex;
  align-items: center;
  width: 100%;
  padding: .375rem .75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  appearance: none;
  border-radius: 0;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  margin-bottom: 20px;
  height: 50px;
}

.form-group {
  position: relative;
  margin-bottom: 1.5rem;
}

.invalid-feedback {
  position: absolute;
  bottom: -20px;
}

.fixed-alert {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #4caf50; 
  color: white; 
  padding: 15px; 
  border-radius: 5px; 
  z-index: 1000; 
}
/* .a93-weldability.a93-pgtable.mn-table table tbody tr td:nth-child(1) {
  text-align: center;
} */

.a93-weldability.a93-pgtable.mn-table table tbody tr:first-child td:first-child {
  text-align: center;
  vertical-align: middle;
}

.a93-weldability.a93-pgtable.mn-table table tbody tr:nth-child(1) td:nth-child(1) {
  /* text-align: left; */
  vertical-align: middle;
}

.brdcrm-banner-section.search-brd-crm {
  background: var(--blue);
}

.search-boxsec input {
  padding: 15px 20px;
  font-size: 21px;
  line-height: 26.2px;
  color: var(--black);
  margin-top: 15px;
  width: 100%;
}
.search-boxsec input::placeholder {
  font-size: 21px;
  line-height: 26.2px;
  color: var(--black);
  
}
.sort-row {}

.search-resultsec a {
    font-size: 16px;
    line-height: 20px;
    color: var(--black);
    position: relative;
}

.search-resultsec a:hover {
    color: var(--orange);
}

.search-resultsec .primary-link--dark:hover {
  color: var(--white);
} 

.search-resultsec a::after {
    width: 100%;
    height: 1px;
    position: absolute;
    left: 0;
    bottom: -4px;
    background: var(--black);
    width: 100%;
    transition: width 0.5s ease;
    content: '';
}
::-webkit-search-cancel-button{
  outline: var(--black);
}
.search-resultsec a:hover::after {
    animation: expandWidth 0.5s forwards;
    background: var(--orange);
}
section.search-resultsec {
  padding: 48px 0;
}
section.search-resultsec {
  padding: 48px 0;
}

.single-result {
  padding: 32px 0;
  border-top: 1px solid var(--black);
}

.single-result:first-child {
  margin-top: 32px;
}

.single-result:last-child {
  margin-bottom: 32px;
}
p.search-title {
  font-size: 21px;
  font-weight: 600;
  line-height: 26.2px;
  color: var(--black);
  margin-bottom: 15px;
}
.sortsec span {
  font-size: 16px;
  line-height: 20px;
  color: var(--black);
  position: relative;
  cursor: pointer;
  transition: 0.5s;
}

.sortsec span:hover {
  color: var(--orange);
}
.sortsec span::after {
  width: 100%;
  height: 1px;
  position: absolute;
  left: 0;
  bottom: -4px;
  background: var(--black);
  width: 100%;
  transition: width 0.5s ease;
  content: '';
}
.sortsec span:hover::after {
  animation: expandWidth 0.5s forwards;
  background: var(--orange);
}
.sortsec a{
  cursor: pointer;
}
.fl-tablesec p {
  margin-top: 20px;
  font-weight: 600;
}
.fl-tablesec p a{
  color: var(--orange);
  text-decoration: underline;
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1.5rem;
}

.modal-header{
  padding: 1rem 1.5rem;
}

.modal button.btn.primary-link.primary-link--dark {
  width: 100%;
}

.modal-title h4 {
  margin: 0;
}

.error-page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 100vh; 
  margin: 0 20px;
}

.error-page h1 {
  font-size: 6rem;
  margin-bottom: 3rem;
  color: var(--orange); 
}

.error-page h3 {
  font-size: 2rem;
  margin-bottom: 1rem;
}

.error-page p {
  font-size: 1.2rem;
  margin-bottom: 3rem;
}
.subtext ol li {
  padding: 0;
  margin: 0;
}
.gridsec .subtext ul li, .gridsec .subtext ol li {
  color: var(--white);
  margin-bottom: 5px;
}
.fencingVineyard-cont .gridsec .subtext ul li, .fencingVineyard-cont .gridsec .subtext ol li {
  color: var(--para-text);
  margin-bottom: 5px;
}
.fencingVineyard-cont .subtext ul li a {
  text-decoration: underline;
}
.logistics-cont .subtext a {
  color: var(--white);
  text-decoration: underline;
}
.loading-animation1 {
  width: 100%;
  height: 40vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--white);
}
.mapsec .mapboxgl-map {
  height: 75vh !important;
}
.fencing .info .row.align-items-center {
  align-items: flex-start !important;
}
.loading-animation1 .loader {
  border-top: 4px solid var(--border3);
}
.subtext ol {
  padding-left: 15px;
}
/* .main-news .row{
  justify-content: center;
} */

button.txtbtn.rd-btn {
  background: transparent;
  text-decoration: underline;
  transition: 0.5s;
  margin-top: 10px;
}

button.txtbtn.rd-btn:hover {
  color: var(--orange);
}

.ql-align-right {
  text-align: right;
}
.ql-align-left {
  text-align: left;
}
.ql-align-center {
  text-align: center;
}
.ql-align-justify {
  text-align: justify;
}