
:root {
    --white: #ffffff;
    --fadedwhite:#ffffffcc;
    --greylight:#F9F9F9; 
    --greyshade1:#F5F5F5;
    --greyshade2:#f2f2f2;
    --grey:#e1e1e1;
    --black: #000000;
    --lightblack:#202020;
    --pink:#FFEEEA;
    --pink2:#FFCFC2; 
    --lightpurple:#B303C3;  
    --blue:#0070C0; 
    --orange:#ff3700;
    --light-orange: #f96246;
    --dark-gray: #414141;
    --para-text: #414141;
    --bg1: #FFF4F4;
    --bg-gradient1: linear-gradient(90deg, #DAD4EC 0%, #DAD4EC 1%, #F3E7E9 100%);
    --bg-gradient2: linear-gradient(72.44deg, rgba(244, 178, 181, 0.3) 0%, rgba(228, 171, 201, 0.3) 100%);
    --bg-gradient3: linear-gradient(90deg, rgba(255, 239, 239, 0.95) 0%, rgba(255, 255, 255, 0.95) 100%);
    --border: #D9D9D9;
    --border2: #3A3A3A;
    --border3: #d8d8d8;
}



@font-face {
    font-family: Gilroy;
    src: url("../fonts/Gilroy-Regular.woff") format("woff");
}
@font-face {
    font-family: Gilroy;
    src: url("../fonts/Gilroy-ExtraBold.woff") format("woff");
    font-weight: 800;
}
@font-face {
    font-family: Gilroy;
    src: url("../fonts/Gilroy-Medium.woff") format("woff");
    font-weight: 500;
}
@font-face {
    font-family: Gilroy;
    src: url("../fonts/Gilroy-SemiBold.woff") format("woff");
    font-weight: 600;
}
@font-face {
    font-family: Gilroy;
    src: url("../fonts/Gilroy-SemiBoldItalic.woff") format("woff");
    font-weight: 600;
    font-style: italic;
}
@font-face {
    font-family: Gilroy;
    src: url("../fonts/Gilroy-Bold.woff") format("woff");
    font-weight: 700;
}
  